import { hideAndShow, updateQueryStringParam, isTabletPortraitWidth, isMobileWidth } from './utils'

export function pricingPage(){
    let pricingLinkClass = document.getElementById('pricing-link-class')
    let pricingBoxClass = document.getElementById('price-box-classes');

    let pricingLinkPass = document.getElementById('pricing-link-pass')
    let pricingBoxPass = document.getElementById('price-box-passes');

    let ppc = document.getElementById('ppc');

    function updateContainerHeight(){
        if (isTabletPortraitWidth()){
            ppc.style.maxHeight = 'none';
        } else {
            let containerHeight = ppc.offsetHeight;
            ppc.style.maxHeight = containerHeight + 'px';
        }
    }

    if (pricingLinkClass){
        pricingLinkClass.addEventListener("click", (ev) => {
            ev.preventDefault()

            updateContainerHeight();
            if (pricingBoxPass.classList.contains('hidden') === false){
                hideAndShow(pricingBoxPass, pricingBoxClass, 300);
                pricingLinkClass.classList.add('btn-white');
                pricingLinkClass.classList.remove('btn-dark');
                pricingLinkPass.classList.add('btn-dark');
                pricingLinkPass.classList.remove('btn-white');
                updateQueryStringParam('class_type', 'classes');
            }
        })
    }

    if (pricingLinkPass){
        pricingLinkPass.addEventListener("click", (ev) => {
            ev.preventDefault();

            updateContainerHeight();
            if (pricingBoxClass.classList.contains('hidden') === false){
                hideAndShow(pricingBoxClass, pricingBoxPass, 300);
                pricingLinkPass.classList.add('btn-white');
                pricingLinkPass.classList.remove('btn-dark');
                pricingLinkClass.classList.add('btn-dark');
                pricingLinkClass.classList.remove('btn-white');
                updateQueryStringParam('class_type', 'passes');
            }
        })
    }
}

export function initClientSubscriptionCancelLink(){
    let cancelLink = document.getElementById('cancel-subscription-link');
    if (cancelLink){
        cancelLink.addEventListener('click', (evt) => {
            evt.preventDefault();
            let url = cancelLink.getAttribute('href');
            let endDate = cancelLink.getAttribute('data-billing-period-end');
            let message = 'Click OK to end your subscription on ' + endDate;
            if (confirm(message)){
                window.location.href = url;
            }
        });
    }
}
