
import './universal'
import { createApp } from 'vue'
import Timetable from './components/timetable/Timetable'
import TimetableHeader from './components/timetable/TimetableHeader'
import moment from 'moment'
import mitt from 'mitt';



window.goy_timeProps = {
    'today': moment().format('DD-MM-YY'),
    'next_week': moment().add(1, 'weeks').startOf('isoWeek').format('DD-MM-YY'),
    'next_month': moment().add(2, 'months').startOf('month').format('DD-MM-YY')
}

const emitter = mitt();

if (document.getElementById('timetable-container')){
    const timetable = createApp(Timetable);
    timetable.config.globalProperties.emitter = emitter
    timetable.mount("#timetable-container");
}

if (document.getElementById('timetable-header-container')){
    const timetableHeader = createApp(TimetableHeader);
    timetableHeader.config.globalProperties.emitter = emitter;
    timetableHeader.mount("#timetable-header-container");
}
