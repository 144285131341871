
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH, TABLET_PORTRAIT_MAX_WIDTH } from './constants'
import { detechWebp } from '@devqin/webp-detect';

export function getScreenWidth(){
    return document.documentElement.getBoundingClientRect().width
}

export function isMobileWidth(){
    return getScreenWidth() < MOBILE_MAX_WIDTH;
}

export function isSmallerThanTabletPortraitWidth(){
    return getScreenWidth() <= TABLET_PORTRAIT_MAX_WIDTH;
}

export function isTabletPortraitWidth(){
    return getScreenWidth() >= MOBILE_MAX_WIDTH  && getScreenWidth() <= TABLET_PORTRAIT_MAX_WIDTH;
}

export function isTabletWidth(){
    return getScreenWidth() >= MOBILE_MAX_WIDTH  && getScreenWidth() <= TABLET_MAX_WIDTH;
}

export function isTabletOrDesktopWidth(){
    return getScreenWidth() >= MOBILE_MAX_WIDTH;
}

export function isTabletLandscapeOrDesktopWidth(){
    return getScreenWidth() >= TABLET_PORTRAIT_MAX_WIDTH;
}

export function isTabletOrMobileWidth(){
    return getScreenWidth() < TABLET_MAX_WIDTH;
}

export function isDesktopWidth(){
    return getScreenWidth() >= TABLET_MAX_WIDTH;
}

export function isIOS(){
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export function hideAndShow(elementToHide, elementToShow, timeout){
    elementToHide.classList.add('hiding')
    setTimeout(()=>{
        elementToHide.classList.remove('hiding')
        elementToHide.classList.add('hidden')
        elementToShow.classList.add('showing');
        elementToShow.classList.remove('hidden')

        setTimeout(() => {
                elementToShow.classList.remove('showing')
        }, timeout)

    }, timeout);

}

export var updateQueryStringParam = function (key, value, navigate=false) {

    var baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
        urlQueryString = document.location.search,
        newParam = key + '=' + value,
        params = '?' + newParam;

    // If the "search" string exists, then build params from it
    if (urlQueryString) {
        var updateRegex = new RegExp('([?&])' + key + '[^&]*');
        var removeRegex = new RegExp('([?&])' + key + '=[^&;]+[&;]?');

        if( typeof value == 'undefined' || value == null || value == '' ) { // Remove param if value is empty
            params = urlQueryString.replace(removeRegex, "$1");
            params = params.replace( /[&;]$/, "" );

        } else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
            params = urlQueryString.replace(updateRegex, "$1" + newParam);

        } else { // Otherwise, add it to end of query string
            params = urlQueryString + '&' + newParam;
        }
    }

    // no parameter was set so we don't need the question mark
    params = params == '?' ? '' : params;
    if (navigate){
        window.location.href = baseUrl + params;
    } else {
        window.history.replaceState({}, "", baseUrl + params);
    }

};

 /***
 * get live runtime value of an element's css style
 *   http://robertnyman.com/2006/04/24/get-the-rendered-style-of-an-element
 *     note: "styleName" is in CSS form (i.e. 'font-size', not 'fontSize').
 ***/
export function getStyle(e, styleName) {
    let styleValue = "";
    if(document.defaultView && document.defaultView.getComputedStyle) {
        styleValue = document.defaultView.getComputedStyle(e, "").getPropertyValue(styleName);
    }
    else if(e.currentStyle) {
        styleName = styleName.replace(/-(\w)/g, function (strMatch, p1) {
            return p1.toUpperCase();
        });
        styleValue = e.currentStyle[styleName];
    }
    return styleValue;
}

export function checkWebP(){
    detechWebp().then(() => {
        document.body.classList.add('webp');
    }).catch(() => {
        document.body.classList.add('no-webp');
    })
 }

 export function decodeEntities(encodedString) {
    var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    var translate = {
        "nbsp":" ",
        "amp" : "&",
        "quot": "\"",
        "lt"  : "<",
        "gt"  : ">"
    };
    return encodedString.replace(translate_re, function(match, entity) {
        return translate[entity];
    }).replace(/&#(\d+);/gi, function(match, numStr) {
        var num = parseInt(numStr, 10);
        return String.fromCharCode(num);
    });
}

let originalText = '';

export function buttonLoading(loaderButton, text){
    if (loaderButton) {
        loaderButton.disabled = true;
        loaderButton.classList.add('disabled');
        originalText = loaderButton.innerText;
        loaderButton.innerText = text;
    }
}

export function buttonActive(loaderButton){
    if (loaderButton) {
        loaderButton.disabled = false;
        loaderButton.classList.remove('disabled');
        loaderButton.innerText = originalText;
        originalText = '';
    }
}

export function pad(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length-size);
}

export function timeFormat(time)
{
    time = Math.max(0, time);
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

// Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
export function throttle(func, wait, options) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  if (!options) options = {};
  var later = function() {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function() {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  }
}

export function isElementInViewport (elem) {
    if (!elem) return false;

    let x = elem.getBoundingClientRect().left;
    let y = elem.getBoundingClientRect().top;
    let ww = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    let hw = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    let w = elem.clientWidth;
    let h = elem.clientHeight;
    return (
        (y < hw &&
         y + h > 0) &&
        (x < ww &&
         x + w > 0)
    );
}

export function validEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

