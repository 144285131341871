<template>
    <div class="video-container" v-on:mouseenter="containerMouseOver" v-on:mouseleave="containerMouseOut">
        <video ref="videoElement" v-on:click="togglePlay" controlsList="nodownload" crossorigin="anonymous" >
            <track v-if="isShowingCaptions" :src="captions_url" label="English" kind="captions"  srclang="en-au" default />
        </video>
        <div v-if="playingNextVideo" class="playing-next-video">
            <h2>Next Video: {{ next_video_title}}...</h2>
            <h1 class="counter">{{nextVideoCounter}}</h1>
        </div>
        <div v-if="showingSubscribePrompt" class="showing-subscribe-prompt">
          <h2>Enjoy this Class?</h2>
          <h3><a href="/studio/shop/online-library-lifetime-access/">Get lifetime access</a> to over 250 classes!</h3>
        </div>
        <div v-if="showVideoCover" class="video-cover" :style="{ backgroundImage: 'url('+this.header_url+')' }" v-on:click="playMedia">
            <h2>{{title}}</h2>
            <div v-if="castIsLoading" class="cast-loading">
                <h4>Casting to Device...</h4>
            </div>
            <div v-else-if="castDeviceName" class="cast-loaded">
                <h4>Casting to {{castDeviceName}}</h4>
            </div>
            <div v-if="! isPlaying && !isCasting && !castIsLoading" class="play-button">

            </div>
        </div>
        <a class="mobile-cast-button svg-icon" v-on:click="toggleChromecast" :title="castButtonTitle" :class="{ 'can-cast': canCast, 'is-casting': isCasting }">
            <svg v-if="isCasting" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <path d="M0 0h48v48H0z" fill="none"/>
                <path d="M2 36v6h6c0-3.31-2.69-6-6-6zm0-8v4c5.52 0 10 4.48 10 10h4c0-7.73-6.27-14-14-14zm36-14H10v3.27c7.92 2.56 14.17 8.81 16.73 16.73H38V14zM2 20v4c9.94 0 18 8.06 18 18h4c0-12.15-9.85-22-22-22zM42 6H6c-2.21 0-4 1.79-4 4v6h4v-6h36v28H28v4h14c2.21 0 4-1.79 4-4V10c0-2.21-1.79-4-4-4z"/>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <path d="M0 0h48v48H0z" fill="none"/>
                <path d="M42 6H6c-2.21 0-4 1.79-4 4v6h4v-6h36v28H28v4h14c2.21 0 4-1.79 4-4V10c0-2.21-1.79-4-4-4zM2 36v6h6c0-3.31-2.69-6-6-6zm0-8v4c5.52 0 10 4.48 10 10h4c0-7.73-6.27-14-14-14zm0-8v4c9.94 0 18 8.06 18 18h4c0-12.15-9.85-22-22-22z"/>
            </svg>
        </a>
        <div class="video-controls" :class="controlClass" >
            <a v-if="prev_video_url" :href="prev_video_url" :title="'Previous Video: '+ prev_video_title" class="prev-video-container">
                <span class="chevron left large"></span>
                <img :src="prev_video_thumb" :alt="prev_video_title"/>
            </a>
            <a v-if="next_video_url" :href="next_video_url" :title="'Next Video: '+next_video_title" class="next-video-container">
                <img :src="next_video_thumb" :alt="next_video_title"/>
                <span class="chevron large"></span>
            </a>
            <div class="controls-container">
                <a class="play-pause-button svg-icon" :class="{ paused : ! isPlaying && ! castPlaying }" :title="playButtonTitle " v-on:click="togglePlay">
                    <svg v-if="isPlaying || castPlaying" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <path d="M12 38h8V10h-8v28zm16-28v28h8V10h-8z"/>
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <path d="M16 10v28l22-14z"/>
                        <path d="M0 0h48v48H0z" fill="none"/>
                    </svg>
                </a>
                <a class="volume-button svg-icon" :class="{ muted: isMuted }" v-on:click="toggleMute" :title="muteButtonTitle">
                    <svg v-if="isMuted" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <path d="M33 24c0-3.53-2.04-6.58-5-8.05v4.42l4.91 4.91c.06-.42.09-.85.09-1.28zm5 0c0 1.88-.41 3.65-1.08 5.28l3.03 3.03C41.25 29.82 42 27 42 24c0-8.56-5.99-15.72-14-17.54v4.13c5.78 1.72 10 7.07 10 13.41zM8.55 6L6 8.55 15.45 18H6v12h8l10 10V26.55l8.51 8.51c-1.34 1.03-2.85 1.86-4.51 2.36v4.13c2.75-.63 5.26-1.89 7.37-3.62L39.45 42 42 39.45l-18-18L8.55 6zM24 8l-4.18 4.18L24 16.36V8z"/>
                    </svg>
                    <svg v-else  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <path d="M6 18v12h8l10 10V8L14 18H6zm27 6c0-3.53-2.04-6.58-5-8.05v16.11c2.96-1.48 5-4.53 5-8.06zM28 6.46v4.13c5.78 1.72 10 7.07 10 13.41s-4.22 11.69-10 13.41v4.13c8.01-1.82 14-8.97 14-17.54S36.01 8.28 28 6.46z"/>
                    </svg>
                </a>
                <div v-if="thumbPath" class="thumbnail-image" :style="{left: thumbX}">
                    <img :src="thumbPath"/>
                    <div class="time">{{ niceSeekTime }} / {{ niceDuration }}</div>
                </div>
                <div class="seek-bar" ref="seekBar" v-on:mousemove="seekHover" v-on:mouseout="closeSeekHover" v-on:touchend="closeSeekHover" v-on:click="onSeekBarClick">
                    <div class="seek-played" :style="{ width: percentPlayed}">

                    </div>
                    <div class="seek-timecode" v-for="timecode in this.timecodes" :key="timecode.timecode" :style="{left: timecode.position }"></div>
                </div>
              <a class="favourite-button svg-icon" v-on:click="toggleFavourite" :title="favouriteButtonTitle" :class="{ 'can-favourite': canFavourite, 'is-favourite': isFavourite }">
                    <svg v-if="isFavourite" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/>
                      <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M0 0h24v24H0z" fill="none"/><path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"/>
                    </svg>
                </a>

                <a class="expand-button svg-icon" v-on:click="toggleFullscreen" :title="expandButtonTitle" :class="{ 'is-casting': isCasting }">
                    <svg v-if="isFullScreen" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <path d="M10 32h6v6h4V28H10v4zm6-16h-6v4h10V10h-4v6zm12 22h4v-6h6v-4H28v10zm4-22v-6h-4v10h10v-4h-6z"/>
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <path d="M14 28h-4v10h10v-4h-6v-6zm-4-8h4v-6h6v-4H10v10zm24 14h-6v4h10V28h-4v6zm-6-24v4h6v6h4V10H28z"/>
                    </svg>
                </a>
                <a class="cast-button svg-icon" v-on:click="toggleChromecast" :title="castButtonTitle" :class="{ 'can-cast': canCast, 'is-casting': isCasting }">
                    <svg v-if="isCasting" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <path d="M0 0h48v48H0z" fill="none"/>
                        <path d="M2 36v6h6c0-3.31-2.69-6-6-6zm0-8v4c5.52 0 10 4.48 10 10h4c0-7.73-6.27-14-14-14zm36-14H10v3.27c7.92 2.56 14.17 8.81 16.73 16.73H38V14zM2 20v4c9.94 0 18 8.06 18 18h4c0-12.15-9.85-22-22-22zM42 6H6c-2.21 0-4 1.79-4 4v6h4v-6h36v28H28v4h14c2.21 0 4-1.79 4-4V10c0-2.21-1.79-4-4-4z"/>
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <path d="M0 0h48v48H0z" fill="none"/>
                        <path d="M42 6H6c-2.21 0-4 1.79-4 4v6h4v-6h36v28H28v4h14c2.21 0 4-1.79 4-4V10c0-2.21-1.79-4-4-4zM2 36v6h6c0-3.31-2.69-6-6-6zm0-8v4c5.52 0 10 4.48 10 10h4c0-7.73-6.27-14-14-14zm0-8v4c9.94 0 18 8.06 18 18h4c0-12.15-9.85-22-22-22z"/>
                    </svg>
                </a>
                <a class="closed-captions svg-icon" v-on:click="toggleClosedCaptions" :title="captionsButtonTitle" :class="{ 'is-casting': isCasting, 'is-showing-captions': isShowingCaptions}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <path d="M38 8H10c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4V12c0-2.21-1.79-4-4-4zM22 22h-3v-1h-4v6h4v-1h3v2c0 1.1-.89 2-2 2h-6c-1.11 0-2-.9-2-2v-8c0-1.1.89-2 2-2h6c1.11 0 2 .9 2 2v2zm14 0h-3v-1h-4v6h4v-1h3v2c0 1.1-.89 2-2 2h-6c-1.11 0-2-.9-2-2v-8c0-1.1.89-2 2-2h6c1.11 0 2 .9 2 2v2z"/>
                    </svg>
                </a>
            </div>

        </div>

    </div>

</template>

<script>
    import Hls from 'hls.js';
    import { pad, timeFormat, isElementInViewport, isMobileWidth } from '../../utils'
    import CastPlayer from './CastPlayer'
    import axios from 'axios'

     export default {
        name: "VideoPlayer",
        data(){
            return {
                video_url: null,
                preview_url: null,
                header_url: null,
                captions_url: null,
                title: null,
                timecodestr: null,
                video_id: null,
                next_video_title: null,
                next_video_url: null,
                next_video_thumb: null,
                prev_video_title: null,
                prev_video_url: null,
                prev_video_thumb: null,
                show_subscribe_prompt: null,
                subscribe_url: null,
                mediaLoaded: false,
                isActive:false,
                isPlaying: false,
                playInterval: false,
                playTimer: 0,
                duration: 0,
                percentPlayed: 0,
                thumbPath: '',
                thumbX: 0,
                isBuffering: false,
                niceSeekTime: '',
                niceDuration: '',
                controlClass: '',
                containerHoverTimer: false,
                isMuted:false,
                isFullScreen: false,
                canCast: false,
                isCasting: false,
                isPictureInPicture: false,
                castPlayer: null,
                captions_enabled: false,
                castPlaying: false,
                castIsLoading: false,
                isShowingCaptions: false,
                castDeviceName: '',
                notifiedWatched: false,
                videoEnded: false,
                timecodes: [],
                playingNextVideo: false,
                showingSubscribePrompt: false,
                nextVideoCounter: 4,
                isFavourite: false,
                canFavourite: false
            }
        },
        mounted() {
            let props = [ 'video_url', 'preview_url', 'header_url', 'captions_url', 'title', 'timecodestr', 'video_id',
              'next_video_title', 'next_video_url', 'next_video_thumb', 'prev_video_title', 'prev_video_url',
              'prev_video_thumb', 'show_subscribe_prompt', 'subscribe_url', 'is_favourite', 'is_subscriber', 'playlist'];
            let playerEl = document.getElementById('video-player');
            if (playerEl){
              props.forEach((key) => {
                const val = playerEl.getAttribute(key);
                if(val !== null) this[key] = (val);
              })
            }
            this.loadMedia()
            if ('pictureInPictureEnabled' in document) {
                this.$refs.videoElement.addEventListener('enterpictureinpicture', this.enteredPictureInPicture);
                this.$refs.videoElement.addEventListener('leavepictureinpicture', this.leftPictureInPicture);
            }
            if (isMobileWidth()){
                this.$refs.videoElement.controls = true
            }
            this.$refs.videoElement.addEventListener('pause', this.onPause);
            this.$refs.videoElement.addEventListener('play', this.onPlay);

            this.castPlayer = new CastPlayer(this);
            this.canFavourite = this.is_subscriber;
            this.isFavourite = this.is_favourite;
        },
        created() {
             if ('pictureInPictureEnabled' in document) {
                  window.addEventListener('scroll', this.handleScroll);
             }
             window.addEventListener('resize', this.handleResize);

             this.emitter.on('timecodeClicked', this.onTimecodeClick)
        },
        unmounted() {
            if ('pictureInPictureEnabled' in document) {
                window.removeEventListener('scroll', this.handleScroll);
            }
            window.removeEventListener('resize', this.handleResize)
        },
        watch: {
            playTimer(newPlayTimer, oldPlayTimer){
                if (this.duration == 0) this.percentPlayed = 0;
                else {
                    let percentage = (this.playTimer / this.duration * 100)
                    this.percentPlayed = percentage + '%';
                    if (percentage > 85 && this.notifiedWatched == false){
                        this.notifyVideoWatched();
                    }
                    if (percentage >= 100 && this.videoEnded == false){
                        this.onVideoEnded()
                    }

                }
            }
        },
        computed: {
            playButtonTitle(){
              if (this.isPlaying){
                  return 'Pause'
              } else {
                  return 'Play'
              }
            },
            muteButtonTitle(){
              if (this.isMuted){
                  return 'Unmute'
              } else {
                  return 'Mute'
              }
            },
            expandButtonTitle(){
                if (this.isCasting){
                    return '';
                } else {
                    if (this.isFullScreen){
                        return 'Exit Fullscreen mode'
                    } else {
                        return 'Enter Fullscreen mode'
                    }
                }
            },
            favouriteButtonTitle(){
              if (this.isFavourite){
                return 'Remove from My Favourites';
              } else {
                return 'Add to My Favourites';
              }
            },
            castButtonTitle(){
              if (this.canCast){
                if (this.isCasting){
                    return 'Stop playing on other device'
                } else {
                    return 'Play on another device'
                }
              } else {
                return 'Cannot play on another device.'
              }
            },
            captionsButtonTitle(){
                if (this.isShowingCaptions){
                    return 'Hide Closed Captions';
                } else {
                    return 'Show Closed Captions';
                }
            },
            showVideoCover(){
                return (! this.isActive || this.isPictureInPicture || this.isCasting) && ! this.playingNextVideo
            }
        },
        methods: {
            async requestPictureInPicture(){
                if (this.$refs.videoElement !== document.pictureInPictureElement){
                    await this.$refs.videoElement.requestPictureInPicture();
                }
            },
            async requestExitPictureInPicture(){
                if (this.$refs.videoElement === document.pictureInPictureElement){
                    await document.exitPictureInPicture();
                }
            },
            onPause(){
                if (! this.isCasting){
                    this.isPlaying = false;
                    this.pageNotPlayingState()
                }
            },
            onPlay(){
                if (! this.isCasting) {
                    this.isPlaying = true;
                    this.pagePlayingState();
                }
            },
            handleScroll(ev){
                if (this.isPlaying && !isElementInViewport(this.$refs.videoElement)){
                    this.requestPictureInPicture()
                } else if (this.isPictureInPicture && isElementInViewport(this.$refs.videoElement)){
                    this.requestExitPictureInPicture()
                }
            },
            handleResize(ev){
                if (isMobileWidth()){
                    this.$refs.videoElement.controls = true
                } else {
                    this.$refs.videoElement.controls = false
                }
            },
            calculateSeekPoints(){
                let timecodes = JSON.parse(this.timecodestr.replace('&quot;', '"'));
                this.timecodes = []; // reset list
                for (var i=0; i<timecodes.length; i++){
                    let timecode = { "time": timecodes[i].time, "title": timecodes[i].title,
                        "position": (timecodes[i].time / this.duration * 100) + '%'}
                    this.timecodes.push(timecode)
                }
            },
            loadMedia(){
                this.notifiedWatched = false;
                if (Hls.isSupported()) {
                    let hls = new Hls({startLevel: -1, maxBufferLength: 30});
                    hls.on(Hls.Events.MANIFEST_PARSED,  (event, data) => {
                        this.mediaLoaded = true
                    });
                    hls.on(Hls.Events.ERROR, (event, data) => {
                        //console.warn('ERROR', data);
                        if (data.details === Hls.ErrorDetails.BUFFER_STALLED_ERROR) {
                            this.isBuffering = true;
                        }
                    });
                    hls.on(Hls.Events.FRAG_BUFFERED, () => {
                      this.isBuffering = false;
                    });
                    hls.loadSource(this.video_url);
                    hls.attachMedia(this.$refs.videoElement);
                }
                else if (this.$refs.videoElement.canPlayType('application/vnd.apple.mpegurl')) {
                    this.$refs.videoElement.src = this.video_url;
                    this.$refs.videoElement.addEventListener('loadedmetadata', () => {
                        this.mediaLoaded = true;
                        this.calculateSeekPoints();
                    });
                    this.$refs.videoElement.addEventListener('ended', this.videoEnded);
                }

            },
            playMedia(time){

                if (this.mediaLoaded){
                    this.$refs.videoElement.play();
                    this.pagePlayingState()
                    this.isPlaying = true;
                    this.isActive = true;
                    this.duration = this.$refs.videoElement.duration;
                    if (this.timecodes.length == 0){
                        this.calculateSeekPoints();
                    }
                    if (typeof time !== 'undefined' && typeof time !== 'object'){
                        this.seekToPoint(time);
                    }

                    this.niceDuration = timeFormat(this.duration)
                    this.playInterval = setInterval(() => {
                        if (this.isCasting){
                            this.playTimer = this.castPlayer.getCurrentMediaTime()
                        } else {
                            if (this.$refs.videoElement){
                                this.playTimer = this.$refs.videoElement.currentTime
                            }
                        }
                    }, 1000)
                }
            },
            togglePlay(){
                if (this.mediaLoaded){
                    if (this.isCasting) {
                        if (this.castPlaying){
                            this.castPlayer.pause();
                            this.castPlaying = false;
                        } else {
                            this.castPlayer.play();
                            this.castPlaying = true;
                        }
                    } else {
                        if (this.isPlaying){
                            this.$refs.videoElement.pause();
                            this.isPlaying = false;
                            this.pageNotPlayingState();
                        }
                        else {
                            this.isActive = true;
                            this.$refs.videoElement.play();
                            this.pagePlayingState()
                            this.isPlaying = true;
                        }
                    }
                }
            },
            toggleMute(){
                if (this.mediaLoaded){
                    if (this.isCasting) {
                        if (this.isMuted){
                            this.$refs.videoElement.muted = false;
                            this.castPlayer.unMute();
                            this.isMuted = false;
                        }
                        else {
                            this.$refs.videoElement.muted = true;
                            this.castPlayer.mute();
                            this.isMuted = true;
                        }
                    }
                    else {
                        if (this.isMuted){
                            this.$refs.videoElement.muted = false;
                            this.isMuted = false;
                        }
                        else {
                            this.$refs.videoElement.muted = true;
                            this.isMuted = true;
                        }
                    }

                }
            },
            toggleClosedCaptions(){
                if(this.mediaLoaded && this.captions_url.length > 0){
                    if (this.isCasting){
                        if (this.isShowingCaptions){
                            this.castPlayer.hideCaptions()
                            this.isShowingCaptions = false;
                        } else {
                            this.castPlayer.showCaptions()
                            this.isShowingCaptions = true;
                        }
                    } else {
                        if (this.isShowingCaptions){
                            this.isShowingCaptions = false;
                        } else {
                            this.isShowingCaptions = true;
                        }
                    }
                }
            },
            toggleFullscreen(){
                if (this.mediaLoaded && ! this.isCasting){
                    if (this.isFullScreen){
                        this.closeFullscreen()
                        this.isFullScreen = false;
                    }
                    else {
                        this.openFullscreen()
                        this.isFullScreen = true;
                    }
                }
            },
            toggleChromecast(){
                if (this.mediaLoaded && this.canCast){
                     if (this.isPlaying) {
                         this.$refs.videoElement.pause();
                         this.isPlaying = false;
                     }
                     if (this.isCasting){
                        this.castPlayer.endSession();
                        this.isCasting = false;

                     } else {
                        this.castPlayer.requestSession()
                     }
                }
            },
            toggleFavourite(){
              if (! this.isFavourite){
                axios.get('/api/video-favourite/'+this.video_id).then(() => {
                    this.isFavourite = true;
                })
              } else {
                axios.get('/api/video-unfavourite/'+this.video_id).then(() => {
                    this.isFavourite = false;
                })
              }
            },
            castLoading(){
                this.$refs.videoElement.pause();
                this.isPlaying = false;
                this.isActive = false;
                this.isCasting = true;
                this.castIsLoading = true;
            },
            castLoaded(castDeviceName, duration){
                this.isActive = false;
                this.castPlaying = true;
                this.controlClass = 'show';
                this.castIsLoading = false;
                this.isCasting = true;
                this.castDeviceName = castDeviceName;
                this.duration = duration;
            },

            castEnded(time){
              this.isActive = true;
              this.castPlaying = false;
              this.isCasting = false;
              this.$refs.videoElement.currentTime = time;
            },
            onCastPlaying(){
                this.castPlaying = true;
            },
            onCastPaused(){
                this.castPlaying = false;
            },
            onCastMuted(){
                this.isMuted = true;
            },
            onCastUnMuted(){
                this.isMuted = false;
            },
            pagePlayingState(){
                document.getElementById('navbar').classList.add('slide-up')
                document.getElementById('main-container').classList.add('video-playing')
            },
            pageNotPlayingState(){
                document.getElementById('navbar').classList.remove('slide-up')
                document.getElementById('main-container').classList.remove('video-playing')
            },
            seekHover(ev){
                if (this.isPlaying || this.isCasting){
                    var rect = this.$refs.seekBar.getBoundingClientRect();
                    var x = ev.clientX - rect.left; //x position within the element.
                    var time = x / rect.width * this.duration
                    var nearest10 = Math.max(1, Math.floor((time) / 10));
                    var min = -5;
                    var max = rect.width;
                    var thumbCalc =  x - 15;
                    if (thumbCalc < min) thumbCalc = min;
                    else if (thumbCalc > max) thumbCalc = max;
                    this.thumbX = (thumbCalc) + 'px';
                    this.thumbPath = this.preview_url + 'pv-' + pad(nearest10, 3) + '.jpg';
                    this.niceSeekTime = timeFormat(x / rect.width * this.duration)
                }
            },
            closeSeekHover(ev){
                setTimeout(() => {
                   this.thumbPath = false;
                }, 1000)
            },
            onSeekBarClick(ev){
                var rect = this.$refs.seekBar.getBoundingClientRect();
                var x = ev.clientX - rect.left; //x position within the element.
                var time = x / rect.width * this.duration
                if (this.isCasting){
                    this.castPlayer.seekTo(time);
                } else {
                    this.$refs.videoElement.currentTime = time
                }
                let percentage = time / this.duration;
                if (percentage > 0.85 && this.notifiedWatched == false){
                    this.notifyVideoWatched()
                }
            },
            onTimecodeClick(time){
                if (!this.isPlaying){
                    this.playMedia(time)
                } else {
                    this.seekToPoint(time)
                }
            },
            seekToPoint(time){
                console.log(time)
                if (this.isCasting){
                    this.castPlayer.seekTo(time);
                } else {
                    this.$refs.videoElement.currentTime = time
                }
            },
            containerMouseOver(){
                this.controlClass = 'show';
                clearTimeout(this.containerHoverTimer)
            },
            containerMouseOut(){
                if (! this.isCasting){
                    this.containerHoverTimer = setTimeout(()=> {
                        this.controlClass = ''
                    }, 3000)
                }
            },
            openFullscreen() {
                let elem = this.$refs.videoElement;
                  if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                  } else if (elem.mozRequestFullScreen) { /* Firefox */
                    elem.mozRequestFullScreen();
                  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                    elem.webkitRequestFullscreen();
                  } else if (elem.msRequestFullscreen) { /* IE/Edge */
                    elem.msRequestFullscreen();
                  }
            },
            closeFullscreen() {
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
              } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
              }
            },
            enteredPictureInPicture(){
                this.isPictureInPicture = true;
            },
            leftPictureInPicture(){
                this.isPictureInPicture = false;
                this.isPlaying = ! this.$refs.videoElement.paused;
            },
            castInitialized(){
                this.canCast = true;
            },
            notifyVideoWatched(){
                axios.get('/api/video-watched/'+this.video_id).then(() => {
                    this.notifiedWatched = true;
                })
            },
            onVideoEnded(){
                this.videoEnded = true;
                if (this.next_video_url && this.next_video_url.length > 0){
                    this.playingNextVideo = true;
                    setInterval(() => {
                        this.nextVideoCounter--;
                    }, 1000);

                    setTimeout(() => {
                        window.location.href=this.next_video_url;

                    }, 3000);
                } else if (this.show_subscribe_prompt && this.show_subscribe_prompt === 'true'){
                  this.showingSubscribePrompt = true;
                }
            }
        }
    };
</script>