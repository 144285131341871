<template>
    <div class="gallery-button-container">
        <a href="#" @click="showModal" class="btn btn-primary btn-show-gallery">View Photos</a>
        <transition name="modal-slow-fade" v-on:before-enter="beforeEnter">
            <Modal v-show="isModalVisible" @close="closeModal" background_class="grey-background" modal_class="gallery-modal" @show="showModal">
                <template v-slot:body class="modal-gallery">
                    <vueper-slides autoplay :slide-ratio="0.74">
                      <vueper-slide v-for="(image, i) in formatted" :key="i" :image="image"></vueper-slide>
                    </vueper-slides>
                </template>
            </Modal>
        </transition>
    </div>
</template>

<script>
    import Modal from './Modal';
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'

    export default {
        name: "GalleryModal",
        components: {
          Modal, VueperSlide, VueperSlides
        },
        props: [],
        computed: {
            formatted(){
                return this.image_urls.split(',');
            }
        },
        data(){
            return {
              image_urls: '',
              isModalVisible: false,
              mediaLoaded: false
            }
        },
        created(){
            this.scrolledHeader = document.getElementById('class-scrolled-header')
            this.navBar = document.getElementById('navbar')
        },
        mounted() {
          let props = ['image_urls'];
          let modalContainer = document.getElementById('gallery-modal-container');
          if (modalContainer){
            props.forEach((key) => {
              const val = modalContainer.getAttribute(key);
              if(val !== null) this[key] = (val);
            })
          }
        },
        methods: {
          beforeEnter(){

          },
          showModal(ev) {
              ev.preventDefault()
              this.isModalVisible = true;
              if (this.scrolledHeader) {
                this.scrolledHeader.classList.add('hide-for-modal');
              }
              if (this.navBar){
                this.navBar.classList.add('hide-for-modal');
              }
          },

          closeModal() {
            this.isModalVisible = false;
            if (this.scrolledHeader) {
                this.scrolledHeader.classList.remove('hide-for-modal');
            }
            if (this.navBar){
                this.navBar.classList.remove('hide-for-modal');
            }
          }
        }
    };
</script>

<style scoped>

</style>