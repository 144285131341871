<template>
    <div class="timetable-header" :class="{ 'show': is_visible }">
        <a class="timetable-header-link" v-on:click="scroll_to_today">Today</a>
        <a class="timetable-header-link" v-on:click="scroll_to_next_week">Next Week</a>
        <a class="timetable-header-link" v-on:click="scroll_to_next_month">Next Month</a>
    </div>
</template>
<script>
    import { isTabletOrMobileWidth } from "../../utils";
    import { NAVBAR_THRESHOLD } from "../../constants";
    import throttle from 'lodash.throttle'

    let navBar = document.getElementById("navbar")

    export default {
        name: "TimetableHeader",
        data() {
            return {
                is_visible: false
            }
        },
        mounted() {
            if (isTabletOrMobileWidth()){
                window.addEventListener('scroll', throttle(this.onScroll, 50), true);
            }

        },
        methods: {
            scroll_to_today(){
                this.emitter.emit('send-scroll', 'today');
            },
            scroll_to_next_week(){
                this.emitter.emit('send-scroll', 'next_week');
            },
            scroll_to_next_month(){
                this.emitter.emit('send-scroll', 'next_month');
            },
            onScroll(){
                if (! this.is_visible && window.scrollY > NAVBAR_THRESHOLD){
                    this.showTimetableHeader()
                } else if (this.is_visible && window.scrollY <= NAVBAR_THRESHOLD){
                    this.hideTimetableHeader()
                }
            },
            showTimetableHeader(){
                navBar.classList.add("slide-up");
                this.is_visible = true;
            },
            hideTimetableHeader(){
                navBar.classList.remove("slide-up")
                this.is_visible = false;
            }
        }
    }
</script>