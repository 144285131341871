
export function checkMovingFormElement(movingInput){
    if (movingInput.value){
        if (movingInput.value.length > 0){
            movingInput.classList.add('has-content')
        } else {
            movingInput.classList.remove('has-content')
        }
    }
}

export function initializeMovingFormElement(movingInput){
    movingInput.addEventListener('change', (ev) => {
        checkMovingFormElement(movingInput);
    })
    movingInput.addEventListener('focus', (ev) => {
        movingInput.parentNode.classList.add('has-focus')
    })
    movingInput.addEventListener('blur', (ev) => {
        movingInput.parentNode.classList.remove('has-focus')
    })
}

export function initializeMovingForms(){
    let formInputs = document.querySelectorAll('.form-group-moving input')
    for (let i = 0, len = formInputs.length; i < len; i++) {
        checkMovingFormElement(formInputs[i])
        initializeMovingFormElement(formInputs[i])
        if (formInputs[i] === document.activeElement){
            formInputs[i].parentNode.classList.add('has-focus')
        }
    }

    let formTextareas = document.querySelectorAll('.form-group-textarea textarea')
    for (let i = 0, len = formTextareas.length; i < len; i++) {
        checkMovingFormElement(formTextareas[i])
        initializeMovingFormElement(formTextareas[i])
        if (formTextareas[i] === document.activeElement){
            formTextareas[i].parentNode.classList.add('has-focus')
        }
    }
}
