import '../css/gardenofyoga.scss'
import './sentry'
import './subscribe'
import './timetable'
import './video-page'
import './class_page'
import './checkout'

import { pricingPage, initClientSubscriptionCancelLink } from './pricing'
import { initialiseClassesSlider } from "./classes_slider";
import { initialiseParallax } from './parallax'
// setup code that has to run on every page
import { setupMenu } from './menu'
import 'remove-focus-outline';
import { checkWebP } from './utils'
import { initializeMovingForms } from './forms'

checkWebP();

 window.addEventListener("load", function(){
     setupMenu();
     pricingPage();
     initialiseClassesSlider();
     initialiseParallax();
     initializeMovingForms();
     initClientSubscriptionCancelLink();
 }, true);

