import './universal'
import throttle from 'lodash.throttle'
import { NAVBAR_THRESHOLD } from "./constants";
import VueScrollTo from 'vue-scrollto'
import { isTabletOrDesktopWidth, isMobileWidth } from "./utils";
import { initialiseGalleryModal } from "./gallery_modal";

window.addEventListener("load", function(){

    let contentLinks = document.getElementsByClassName('class-content-section-link')

    for(let i = 0; i < contentLinks.length; i++) {
        let activeLink = contentLinks[i];
        activeLink.addEventListener("click", function(ev) {
            ev.preventDefault();
            let targetElement = event.target || event.srcElement;
            let id = targetElement.getAttribute('href').substring(1);
            let contentSection = document.getElementById('section-' + id);
            contentSection.classList.add('show');
        })
    }

    let closeLinks = document.getElementsByClassName('class-close-content-section-link');

    for(let i = 0; i < closeLinks.length; i++) {
        let closeLink = closeLinks[i];
        closeLink.addEventListener("click", function(ev) {
            ev.preventDefault();
            let targetElement = event.target || event.srcElement;
            let id = targetElement.getAttribute('href').substring(1);
            let contentSection = document.getElementById('section-' + id);
            contentSection.classList.remove('show');
        })
    }

    let scrollLinks = document.getElementsByClassName('class-scroll-section-link')

    for(let i = 0; i < scrollLinks.length; i++) {
        let activeLink = scrollLinks[i];
        activeLink.addEventListener("click", function(ev) {
            ev.preventDefault();
            let targetElement = event.target || event.srcElement;
            let id = targetElement.getAttribute('href').substring(1);
            VueScrollTo.scrollTo('#section-' + id, 500, { offset: -50 })
        })
    }

    initialiseGalleryModal();
});

window.addEventListener("scroll", throttle(() => {
    let headerElement = document.getElementById('class-scrolled-header');
    let navBar = document.getElementById("navbar");
    if (isTabletOrDesktopWidth() && headerElement){
        if(window.scrollY > NAVBAR_THRESHOLD){
            headerElement.classList.add('scrolled')
            navBar.classList.add("slide-up");
        } else {
            headerElement.classList.remove('scrolled')
            navBar.classList.remove("slide-up");
        }
    }

}), 50);
