import Hls from 'hls.js';
import { isTabletOrDesktopWidth, updateQueryStringParam, isMobileWidth } from './utils';
import { createApp } from 'vue'
import VideoModal from './components/modals/VideoModal'
import VideoPlayer from "./components/player/VideoPlayer";
import VideoSlider from "./components/player/VideoSlider"
import mitt from 'mitt';

export function initialiseVideoHeader(){
    if(isTabletOrDesktopWidth()){
        let video = document.getElementById('header-video');
        if (video){
            video.addEventListener('loadeddata', function() {
                video.classList.add('show');
                }, false);
            let source = video.getAttribute('data-src');
              if(Hls.isSupported()) {
                let hls = new Hls({startLevel: -1, maxBufferLength: 30});
                hls.loadSource(source);
                hls.attachMedia(video);
                hls.on(Hls.Events.MANIFEST_PARSED,function() {
                  video.play();
                });
             }
            else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.src = source;
                video.addEventListener('loadedmetadata',function() {
                    video.play();
                });
            }
        }
    }
}


export function showVideoModal(){
    let modal = document.getElementById('video-modal-container');
    if (modal){
        const videoModalApp = createApp(VideoModal);
        videoModalApp.mount("#video-modal-container");
    }

}

export function initialiseVideoPlayer(){
    if (document.getElementById('video-player')){
        const emitter = mitt();

        const videoPlayerApp = createApp(VideoPlayer);
        videoPlayerApp.config.globalProperties.emitter = emitter;
        videoPlayerApp.mount("#video-player");


        let timecodeLinks = document.getElementsByClassName('timecode-link')

        for(let i = 0; i < timecodeLinks.length; i++) {
            let activeLink = timecodeLinks[i];
            activeLink.addEventListener("click", function(ev) {
                ev.preventDefault();
                let targetElement = event.target || event.srcElement;
                let time = targetElement.getAttribute('data-time');
                emitter.emit('timecodeClicked', Number(time));
            })
        }
    }
}

export function initialiseVideoSlider(){
    let slider = document.getElementById('videos-slider');
    if (slider) {
        const videoSliderApp = createApp(VideoSlider);
        videoSliderApp.mount("#videos-slider");
    }
}

export function initialiseVideoFilters(){
    let buttons = document.getElementsByClassName('video-instructor-checkbox')
    if (buttons){
        for(let i=0;i<buttons.length; i++){
            let button = buttons[i];
            button.addEventListener("click", (evt) => {
                for(let j=0;j<buttons.length; j++){
                    if (j !== i){
                        buttons[j].checked = false;
                    }
                }
                let buttonVal = null;
                if (button.checked) buttonVal = button.value;
                updateQueryStringParam('teacher', buttonVal, true);
            });
        }
    }
    let categorySelect = document.getElementById('video-category-select');
    if (categorySelect){
        categorySelect.addEventListener("change", (evt) => {
            updateQueryStringParam('category', categorySelect.value, true);
        })
    }
}

