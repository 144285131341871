import { isTabletLandscapeOrDesktopWidth } from "./utils";

export function initialiseParallax(){

    let parallaxEls = document.getElementsByClassName('parallax');
    if (parallaxEls.length > 0 && isTabletLandscapeOrDesktopWidth()){

        let topDistance = 100;
        const defaultRate = 0.12;
        let parallaxItems = {};
        Array.prototype.forEach.call(parallaxEls, el => {
            el.style.opacity = 1;
            parallaxItems[el.id] = Number(el.getAttribute('data-rate')) || defaultRate;
        });
        let movement = 0;
        let translate3d = '';

        let checkParallax = function(){
            let topDistance = window.pageYOffset;
            Array.prototype.forEach.call(parallaxEls, el => {
                movement = -(topDistance * parallaxItems[el.id]);
                translate3d = 'translate3d(0, ' + movement + 'px, 0)';
                el.style.transform= translate3d;
                el.style.WebkitTransform = translate3d;
                el.style.MozTransform = translate3d;
                el.style.msTransform = translate3d;
                el.style.OTransform = translate3d
            });
        }

        window.addEventListener('scroll', function(){
         requestAnimationFrame(checkParallax);
        });
        checkParallax();
    }
}
