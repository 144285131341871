
import { MOBILE_MAX_WIDTH } from "./constants";
import { isSmallerThanTabletPortraitWidth } from "./utils";

let menuTimeout = null;

function doMenuMouseOver(navItem, activeItemSelector){
    clearTimeout(menuTimeout)
    let activeEl = document.querySelector(activeItemSelector)
    if (activeEl && activeEl !== navItem)
        activeEl.classList.remove('show')
    navItem.classList.add('show')
    menuTimeout = setTimeout(() =>{
        navItem.classList.remove('show')
    }, 2000)
}

function doMenuTouch(navItem, activeItemSelector){
    let activeEl = document.querySelector(activeItemSelector)
    if (activeEl  && activeEl !== navItem) {
        activeEl.classList.remove('show')
    }

    if (navItem.classList.contains('show')){
        navItem.classList.remove('show')
    } else {
        navItem.classList.add('show')
    }
}

export function setupMenu(){
    //console.log(window);
    let navBar = document.getElementById("navbar")
    if (isSmallerThanTabletPortraitWidth()){
        let toggleMore = document.getElementById("more-toggle")
        let fullPageNav = document.getElementById("fullpage-nav")
        toggleMore.addEventListener("click", function(ev){
            ev.preventDefault()
            fullPageNav.classList.add("show")
            navBar.classList.add("slide-up")
        });

        let closeNav = document.getElementById("close-fullpage-menu")
        closeNav.addEventListener("click", function(ev){
            ev.preventDefault()
            fullPageNav.classList.remove("show")
            navBar.classList.remove("slide-up")
        });
    } else {
        const navItems = document.querySelectorAll('.navbar-nav > li.dropdown')

        for(let i=0; i< navItems.length; i++){
            let navItem = navItems[i]
            navItem.addEventListener('mouseover', function(ev){
                doMenuMouseOver(navItem, '.navbar-nav > li.dropdown.show, #user-nav.show')
            });

            navItem.addEventListener('touchend', function(ev){
                ev.preventDefault()
                doMenuTouch(navItem, '.navbar-nav > li.dropdown.show, #user-nav.show')
            })
        }

        const subLinks = document.querySelectorAll('.navbar-nav > li.dropdown ul li a')
        for(let i=0; i< subLinks.length; i++) {
            let subLink = subLinks[i]
            subLink.addEventListener('touchend', function(ev){
                ev.stopPropagation();
            })
        }
    }

    let userNav = document.getElementById('user-nav');
    if (userNav){

        userNav.addEventListener('mouseover', function(ev) {
            doMenuMouseOver(userNav, '.navbar-nav > li.dropdown.show')
        });
        userNav.addEventListener('touchend', function(ev) {
            ev.preventDefault()
            doMenuTouch(userNav, '.navbar-nav > li.dropdown.show')
        });
        const navSublinks = document.querySelectorAll('#user-nav ul li a')
        for(let i=0; i< navSublinks.length; i++) {
            let navSublink = navSublinks[i]
            navSublink.addEventListener('touchend', function(ev){
                ev.stopPropagation();
            })
        }
    }

    let userNavLink = document.getElementById('user-nav-link');
    let userNavMenu = document.getElementById('client-navbar');
    if (userNavLink && userNavMenu){
        userNavLink.addEventListener('click', function(ev) {
            ev.preventDefault();
            userNavMenu.classList.add('open');
        });
        userNavLink.addEventListener('touchend', function(ev) {
            ev.preventDefault();
            userNavMenu.classList.add('open');
        });
    }

    let closeUserNav = document.getElementById('close-client-navbar');
    if (closeUserNav && userNavMenu){
        closeUserNav.addEventListener('click', function(ev){
            ev.preventDefault();
            if (userNavMenu.classList.contains('open')){
                userNavMenu.classList.remove('open');
            }
        })

    }

}

