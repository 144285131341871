import { getStyle } from "./utils";


export function initialiseClassesSlider(){
    let sliderElement = document.getElementById('disc');

    if (sliderElement){
        let sliderItems = document.getElementsByClassName('item-slider-item');
        if (sliderItems == null|| sliderItems.length == 0) return;

        let sliderPos = 0;
        let viewportElement = document.getElementById('isv')
        let sliderInner = document.getElementById('isi')
        let nextLink = document.getElementById('isnb');
        let prevLink = document.getElementById('ispb')
        let itemWidth = sliderItems[0].clientWidth;
        let sliderRequired = false;
        if (viewportElement && (viewportElement.clientWidth < sliderItems.length * itemWidth)){
            nextLink.classList.add('active');
            sliderRequired = true;
        }

        let moveTo = function(sliderIndex){
            let elementRightMargin = getStyle(sliderItems[0], 'margin-right');
            elementRightMargin = elementRightMargin.substring(0, elementRightMargin.length - 2);

            let pos = (sliderIndex * (itemWidth + Number(elementRightMargin))) * -1;

            let animationTime = 500;
            let easing = "ease";
            let transformCSS = "-webkit-transform: translate3d( " + pos + "px, 0, 0); -webkit-transition: -webkit-transform " + animationTime + "ms " + easing + "; -moz-transform: translate3d(" + pos + "px, 0, 0); -moz-transition: -moz-transform " + animationTime + "ms " + easing + "; -ms-transform: translate3d(" + pos + "px, 0, 0); -ms-transition: -ms-transform " + animationTime + "ms " + easing + "; transform: translate3d(" + pos + "px, 0, 0); transition: transform " + animationTime + "ms " + easing + ";";
            sliderInner.style.cssText = transformCSS;
        }

        let updateButtonStates = function(){
            if (sliderRequired){
                if (sliderPos > 0){
                    prevLink.classList.add('active');
                } else {
                    prevLink.classList.remove('active');
                }

                if (sliderPos < sliderItems.length - 3){
                    nextLink.classList.add('active');
                } else {
                    nextLink.classList.remove('active');
                }
            }
        }

        let resetSlider = function(){
            itemWidth = sliderItems[0].clientWidth;
            if (viewportElement.clientWidth < sliderItems.length * itemWidth){
                nextLink.classList.add('active');
                sliderRequired = true;
            } else {
                sliderRequired = false;
            }

            sliderPos = 0;
            updateButtonStates();
            moveTo(sliderPos)
        }
        if (nextLink) {
            nextLink.addEventListener('click', function(ev){
                ev.preventDefault();
                if (nextLink.classList.contains('active')){
                    sliderPos++;
                    moveTo(sliderPos);
                    updateButtonStates()
                }
            })
        }

        if (prevLink) {
            prevLink.addEventListener('click', function (ev) {
                ev.preventDefault();
                if (prevLink.classList.contains('active')) {
                    sliderPos--;
                    moveTo(sliderPos);
                    updateButtonStates()
                }
            });
        }

        window.addEventListener("resize", function(){
            resetSlider();
        });
    }
}