<template>
    <div :class="[day_class, is_today, is_next_week, is_next_month]">
        <div v-if="day.new_month" class="timetable-month">{{ day.month }}</div>
        <div class="timetable-day">
            <div class="timetable-day-header">
                <h2>{{ day.name }}&nbsp;</h2>
                <h2 class="timetable-date">{{ day.nice_date }}</h2>
            </div>
            <div class="timetable-sessions-container morning-sessions" :class="{ empty: morning_empty }">
                <div class="timetable-sessions" v-for="(occurrence, index) in day.sessions.morning" :key="occurrence.id" >
                    <TimetableSession :occurrence="occurrence" :index="index" :booked_ids="booked_ids" :waitlist_ids="waitlist_ids"></TimetableSession>
                </div>
            </div>
            <div class="timetable-sessions-container afternoon-sessions" :class="{ empty: afternoon_empty }">
                <div class="timetable-sessions" v-for="(occurrence, index) in day.sessions.afternoon" :key="occurrence.id" >
                    <TimetableSession :occurrence="occurrence" :index="index" :booked_ids="booked_ids" :waitlist_ids="waitlist_ids"></TimetableSession>
                </div>
            </div>
            <div class="timetable-sessions-container evening-sessions" :class="{ empty: evening_empty }">
                <div class="timetable-sessions" v-for="(occurrence, index) in day.sessions.evening" :key="occurrence.id" >
                    <TimetableSession :occurrence="occurrence" :index="index" :booked_ids="booked_ids" :waitlist_ids="waitlist_ids"></TimetableSession>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import TimetableSession from './TimetableSession'

    export default {
        name: "TimetableDay",
        props: ['day', 'index', 'booked_ids', 'waitlist_ids'],
        components: {
          TimetableSession
        },
        computed: {
            day_class(){
                return 'day-'+this.day.id
            },
            morning_empty(){
                return this.day.sessions.morning.length == 0;
            },
            afternoon_empty(){
                return this.day.sessions.afternoon.length == 0;
            },
            evening_empty(){
                return this.day.sessions.evening.length == 0;
            },
            is_today(){
                if (window.goy_timeProps.today === this.day.id){
                    return 'is_today'
                }
                return ''
            },
            is_next_week(){
                if (window.goy_timeProps.next_week === this.day.id){
                    return 'is_next_week'
                }
                return ''
            },
            is_next_month(){
                if (window.goy_timeProps.next_month === this.day.id){
                    return 'is_next_month'
                }
                return ''
            }

        }
    };
</script>

<style scoped>

</style>