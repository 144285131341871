<template>
        <div class="modal-overlay" :class="background_class" @click.self="close">
            <a @click="close" class="action-close-modal" aria-label="Close Modal"></a>
            <div class="modal" :class="modal_class" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <div class="modal-content ">
                  <slot name="header">
                  </slot>

                  <slot name="body">
                  </slot>

                  <slot name="footer">
                  </slot>
                </div>
            </div>
        </div>

</template>
<script>
    export default {
    name: 'Modal',
    props: ['background_class', 'modal_class'],
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>
