import Formset from './django-formset'
import { initializeCreditCardForm} from "./credit-card";
import { buttonLoading, buttonActive } from "./utils";
import axios from 'axios';
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
//import Vue from 'vue';

let addFriendContainer = document.getElementById('add-friend-container');
if (addFriendContainer){
    let addFriendForm = document.getElementById('id_empty_form')
    let formsetOptions = {
        add_btn_text: 'Add Friend',
        add_btn_container: addFriendContainer,
        delete_btn_text: 'Remove',
        prefix: 'form',
        delete_class_btn: 'delete-friend',
        form_container_classes: 'relative add-friend-group'
    }
    let f = new Formset(addFriendForm, formsetOptions)
    f.create()
}

let makeBookingButton = document.getElementById('btn-make-booking');
if (makeBookingButton){
    let errorBlock = document.getElementById('bottom-error-message')
    makeBookingButton.addEventListener('click', function(ev){
        ev.preventDefault();
        errorBlock.classList.add('hidden')
        let formCount = document.getElementById('id_form-TOTAL_FORMS').value;
        if (formCount == 1){
            buttonLoading(makeBookingButton, 'Processing...')
            document.getElementById('make-booking-form').submit()
        } else {
            let emailList = [];
            let hasDuplicates = false;
            for(let i=0; i<formCount; i++){
                let emailAddress = document.getElementById('id_form-'+i+'-email').value
                emailList.push(emailAddress)
            }
            emailList.sort();
            for (let i = 0; i < emailList.length - 1; i++) {
                if (emailList[i + 1] == emailList[i]) {
                    hasDuplicates = true;
                    break
                }
            }
            if (hasDuplicates){
                errorBlock.innerHTML = '<p>Sorry! Each person in the booking must have their own email address.</p>'
                errorBlock.classList.remove('hidden')
                let emailInputs = document.querySelectorAll('input[type="email"]');
                for (let i=1; i<emailInputs.length; i++){ // first one is hidden
                    let emailInput = emailInputs[i];
                    emailInput.addEventListener('focus', () =>{
                        errorBlock.classList.add('hidden')
                    })
                }
            } else {
                buttonLoading(makeBookingButton, 'Processing...')
                document.getElementById('make-booking-form').submit()
            }
        }
    })

}

let initialiseSimplePaymentForm = function(){
    let paymentButton = document.querySelector('.btn-payment-form')
    if (paymentButton){
        paymentButton.addEventListener('click', function(ev){
            let paymentForm = document.getElementById('payment_form');
            if (paymentForm) {
                buttonLoading(paymentButton, 'Processing...')
                paymentForm.submit();
            }
        })
    }
}

let creditCardForm = document.getElementById('payment_form')
if (creditCardForm && creditCardForm.classList.contains('form-type-stripe')){
    let cardNumberEl = document.getElementById('card-element')
    window.addEventListener("load", function(){
        if (cardNumberEl){
            initializeCreditCardForm()
        } else {
            initialiseSimplePaymentForm()
        }
    })
} else {
   initialiseSimplePaymentForm()
}


let cancelButton = document.getElementById('action-cancel-booking')
if (cancelButton){
    cancelButton.addEventListener('click', function(ev){
        if (! confirm('Are you sure you want to cancel your booking?')){
            ev.preventDefault();
        }
    });
}

let giftCheckboxes = document.getElementsByClassName('gift-checkbox');
for(let i=0; i<giftCheckboxes.length; i++){
    let checkbox = giftCheckboxes[i];
    checkbox.addEventListener('click', function(ev){
        let idNum = ev.target.id.match(/\d+/);
        console.log('id number '+ idNum)
        let messageContainer = document.getElementById('gift-voucher-message-container-form-' + idNum);
        messageContainer.classList.toggle('hidden')
        let formContainer = document.getElementById('gift-voucher-form-container-form-' + idNum);
        formContainer.classList.toggle('hidden');
    });
}

let addToCartButtons = document.getElementsByClassName('add-to-cart');

for(let i=0; i<addToCartButtons.length; i++){
    let button = addToCartButtons[i];
    button.addEventListener('click', function(ev){
        let idNum = ev.target.getAttribute('data-id');
        let params = '';
        let variantSelector = document.getElementById('select-variant-' + idNum);
        if (variantSelector){
            let variantId = variantSelector.value;
            params = '?variant=' + variantId
        }
        buttonLoading(button, '  ')
        axios.get('/api/add-to-cart/' + idNum + '/' + params).then((response) => {
            let minicart = document.getElementById('minicart');
            if (minicart){
                minicart.classList.add('active');
                document.getElementById('minicart-count').innerHTML = response.data.items;
                document.getElementById('minicart-value').innerHTML = '$' + response.data.value;
            }
            buttonActive(button)
        })
    });
}

function delay(fn, ms) {
  let timer = 0
  return function(...args) {
      console.log(args);
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

function initCheckoutPage(){

    // checkout page
    let removeFromCartButtons = document.getElementsByClassName('action-remove-cart');

    for(let i=0; i<removeFromCartButtons.length; i++){
        let button = removeFromCartButtons[i];
        button.addEventListener('click', function(ev){
            ev.preventDefault();
            let url = ev.target.getAttribute('href');
            let id = ev.target.getAttribute('data-id');

            axios.get(url).then((response) => {
                let cartItem = document.getElementById('cart-item-' + id);
                if (cartItem){
                    cartItem.classList.add('remove');
                    window.setTimeout(() => {
                        cartItem.parentElement.removeChild(cartItem);
                    }, 500);
                }
                let cartTotal = document.getElementById('cart-total');
                if (cartTotal){
                    let total = Number(response.data.cart.total_value.amount).toFixed(2);
                    cartTotal.innerHTML = '$' + total
                }

            })
        });
    }

    let applyDiscount = document.getElementById('activate-discount-code');
    if (applyDiscount){
        applyDiscount.addEventListener('click', function(ev) {
            ev.preventDefault();
            let url = applyDiscount.getAttribute('data-href');
            let discountField = document.getElementById('discount-field');
            if (discountField.value.length < 3){
                return;
            }
            let data = { 'code': discountField.value };
            axios.post(url, data).then((response) => {
                window.location.reload();
            }).catch((error) => {
                console.log(error);
                if (error.response.status === 401){
                    document.getElementById('discount-error').classList.add('show-item');
                }
            });
        });
    }

    let discountField = document.getElementById('discount-field')
    if (discountField){
        discountField.addEventListener('keyup', function(ev) {
            document.getElementById('discount-error').classList.remove('show-item');
        })
    }

    let removeDiscountField = document.getElementById('action-clear-discount');
    if (removeDiscountField){
        removeDiscountField.addEventListener('click', function(ev) {
            ev.preventDefault();
            let url = ev.target.getAttribute('href');
            axios.get(url).then((response) => {
                window.location.reload();
            });
        });
    }

    function checkQuantityInput(ev){
        let quantity = ev.target.value;
        let id = ev.target.getAttribute('data-id');
        let url = ev.target.getAttribute('data-href')
        clearTimeout(timers[id]);
        timers[id] = setTimeout(() => {
            if (! isNaN(quantity)){
                let data = { 'quantity': quantity }
                axios.post(url, data).then((response) => {
                    window.location.reload();
                });
            }
        }, 500)
    }

    let quantityFields = document.getElementsByClassName('checkout-quantity');
    let timers = {};
    for(let i=0; i<quantityFields.length; i++) {
        let field = quantityFields[i];

        let id = field.getAttribute('data-id');
        timers[id] = 0;
        field.addEventListener('keyup', checkQuantityInput);
        field.addEventListener('change', checkQuantityInput);
    }
}

if (document.getElementsByClassName('action-remove-cart')){
    initCheckoutPage();
}




