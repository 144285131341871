<template>
    <div>
        <div class="desktop-item-slider-container" v-if="items.length > 0">
            <div class="item-slider-button-container">
                <a href="#" :class="'item-slider-prev-button item-slider-button-container-prev '+prev_link_class" v-on:click="prev_button_click"></a>
            </div>
            <div class="item-slider-viewport" ref="viewport">
                <div class="item-slider-inner" :style="slider_inner_style">
                    <VideoSliderItem v-for="(video, i) in items" :key="i" :video="video" ref="items"></VideoSliderItem>
                </div>
            </div>
            <div class="item-slider-button-container item-slider-button-container-next">
                <a href="#" :class="'item-slider-next-button '+next_link_class" v-on:click="next_button_click"></a>
            </div>
        </div>
        <div v-else class="loading-icon"></div>
    </div>
</template>
<script>
    import axios from 'axios'
    import  VideoSliderItem from './VideoSliderItem'
    import {getStyle} from "../../utils";

    export default {
        name: "VideoSlider",
        components: {
          VideoSliderItem
        },
        props: [],
        data: function () {
            return {
                video_id: null,
                teacher_id: null,
                course_id: null,
                not_subscribers: null,
                subscriber_only: null,
                items: [],
                loading: true,
                slider_pos: 0,
                item_width: 0,
                slider_required: false,
                next_link_class: '',
                prev_link_class: '',
                slider_inner_style: ''
            }
        },
        mounted() {
            let props = ['video_id', 'teacher_id', 'course_id', 'playlist', 'curated', 'not_subscribers', 'subscriber_only'];
            let sliderEl = document.getElementById('videos-slider');
            if (sliderEl){
              props.forEach((key) => {
                const val = sliderEl.getAttribute(key);
                if(val !== null) this[key] = (val);
              })
            }

            const url = '/api/videos';
            let params = {}
            if (this.video_id){
                params['id'] = this.video_id
            }
            if (this.teacher_id){
                params['teacher_id'] = this.teacher_id
            }
            if (this.course_id){
                params['course_id'] = this.course_id;
            }
            if (this.playlist){
              params['playlist'] = this.playlist;
            }
            if (this.curated){
              params['curated'] = this.curated;
            }
            if (this.not_subscribers){
                params['not_subscribers'] = true;
            }else if (this.subscriber_only){
                params['subscriber_only'] = true;
            }

            return axios.get(url, {params: params}).then(response => {
                    this.items = response.data.results;
                    this.loading = false;
                    if (this.items.length > 0){
                        this.$nextTick(this.set_up_slider)
                    }
                });
        },
        methods:{
            set_up_slider(){
                this.item_width = this.$refs.items.$refs.item.clientWidth;
                if (this.$refs.viewport.clientWidth < this.items.length * this.item_width){
                    this.next_link_class = 'active';
                    this.slider_required = true;
                }

                window.addEventListener("resize", this.reset_slider);
            },
            move_to(sliderIndex){
                let elementRightMargin = getStyle(this.$refs.items.$refs.item, 'margin-right');
                elementRightMargin = elementRightMargin.substring(0, elementRightMargin.length - 2);

                let pos = (sliderIndex * (this.item_width + Number(elementRightMargin))) * -1;

                let animationTime = 500;
                let easing = "ease";
                let transformCSS = "-webkit-transform: translate3d( " + pos + "px, 0, 0); -webkit-transition: -webkit-transform " + animationTime + "ms " + easing + "; -moz-transform: translate3d(" + pos + "px, 0, 0); -moz-transition: -moz-transform " + animationTime + "ms " + easing + "; -ms-transform: translate3d(" + pos + "px, 0, 0); -ms-transition: -ms-transform " + animationTime + "ms " + easing + "; transform: translate3d(" + pos + "px, 0, 0); transition: transform " + animationTime + "ms " + easing + ";";
                this.slider_inner_style = transformCSS
            },
            update_button_states(){
                if (this.slider_required){
                    if (this.slider_pos > 0){
                        this.prev_link_class = 'active'
                    } else {
                        this.prev_link_class = ''
                    }

                    if (this.slider_pos < this.items.length - 1){
                        this.next_link_class = 'active'
                    } else {
                        this.next_link_class = ''
                    }
                }
            },
            next_button_click(ev){
                ev.preventDefault()
                if (this.next_link_class === 'active'){
                    this.slider_pos ++;
                    this.move_to(this.slider_pos);
                    this.update_button_states();
                }
            },
            prev_button_click(ev){
                ev.preventDefault()
                if (this.prev_link_class === 'active'){
                    this.slider_pos--;
                    this.move_to(this.slider_pos);
                    this.update_button_states();
                }
            },
            reset_slider(){
                this.item_width = this.$refs.items[0].$refs.item.clientWidth;

                if (this.$refs.viewport.clientWidth < this.items.length * this.item_width){
                    this.next_link_class = 'active';
                    this.slider_required = true;
                } else {
                    this.slider_required = false;
                }

                this.slider_pos = 0;

                this.move_to(this.slider_pos);
                this.update_button_states();
            }
        }
    }
</script>