<template>
    <div>
        <div class="timetable-buttons-container">
            <div>
                <a class="prev-week-button week-button" :class="{ 'is_loading': ! loaded }" v-on:click="getPrevWeek">Prev Week</a>
            </div>
            <div>
                <a class="next-week-button week-button" :class="{ 'is_loading': ! loaded }" v-on:click="getNextWeek">Next Week</a>
            </div>
        </div>
        <template v-if="displayTimetable">
            <div class='scrolling-component' ref='scrollComponent'>
              <div class="timetable">
                  <div v-if="days.length === 0" class="timetable-no-classes">
                      There are no classes scheduled this week, please try next week!</div>
                  <div class="timetable-days" v-for="(day, index) in days" :key="day.id" >
                      <TimetableDay :day="day" :index="index" :booked_ids="processed_booked_ids" :waitlist_ids="processed_waitlist_items"></TimetableDay>
                  </div>
              </div>
            </div>

        </template>
        <div v-else-if="displaySpinner">
            <div class="timetable-loading">
                <div class="timetable-spinner"></div>
            </div>
        </div>
        <div class="timetable-bottom"></div>
    </div>
</template>
<script>
    import axios from 'axios';
    import TimetableDay from './TimetableDay'
    import moment from 'moment'
    import { isDesktopWidth, isTabletOrMobileWidth, updateQueryStringParam, decodeEntities } from '../../utils'
    import VueScrollTo from 'vue-scrollto'
    import { MORNING_CUTOFF, AFTERNOON_CUTOFF } from "../../constants";

    export default {
        name: "timetable",
        components: {
          TimetableDay
        },
        data() {
            return {
                waitlist_items_data: null,
                booking_data:null,
                days: null,
                loaded: false,
                initialLoaded: false,
                distance: -Infinity,
                initialized: false,
                next_link: `/api/timetable/`,
                last_day: '',
                last_month: '',
                first_next_link: null,
                first_render: true
            }
        },
        computed: {
            processed_booked_ids(){
                if (this.booking_data && this.booking_data.length > 0) {
                    return JSON.parse(decodeEntities(this.booking_data))
                } else {
                    return []
                }
            },
            processed_waitlist_items(){
                if (this.waitlist_items_data && this.waitlist_items_data.length > 0) {
                    return JSON.parse(decodeEntities(this.waitlist_items_data))
                } else {
                    return []
                }
            },
            displayTimetable(){
              return this.loaded || (this.initialLoaded && isTabletOrMobileWidth());
            },
            displaySpinner(){
              return isDesktopWidth() || ! this.initialLoaded;
            },
        },
        created() {
            this.emitter.on('send-scroll', (data) => {
              switch(data) {
                  case 'next_month': this.scroll_to_next_month(); break;
                  case 'next_week': this.scroll_to_next_week(); break;
                  case 'today': this.scroll_to_today(); break;
              }
            }, this);
            window.addEventListener('resize', this.onResize, true);
            this.initialized = true;

            let dataEl = document.getElementById('timetable-header-container');
            let start = dataEl.getAttribute('data-start');
            if (start !== null && start.length > 0 && isDesktopWidth()){
                this.next_link = `/api/timetable/?start=` + start
            }
            this.retrieveSchedule(null);
        },
        updated(){
            if (this.first_render && isTabletOrMobileWidth()){
                VueScrollTo.scrollTo('.is_today', 600, {
                    easing: 'ease-in-out',
                    offset: -120,
                });
                this.first_render = false;
            }

        },
        mounted(){
          window.addEventListener("scroll", this.handleScroll);
          let props = [ 'waitlist_items_data', 'booking_data'];
            let playerEl = document.getElementById('timetable-container');
            if (playerEl){
              props.forEach((key) => {
                const val = playerEl.getAttribute(key);
                if(val !== null) this[key] = (val);
              })
            }
        },
        unmounted() {
          window.removeEventListener("scroll", this.handleScroll)
        },
        methods: {

            handleScroll(ev){
              if (isTabletOrMobileWidth()){
                let element = this.$refs.scrollComponent;
                  if (element && element.getBoundingClientRect().bottom < window.innerHeight ) {
                    this.getNextWeek()
                  }
              }
            },
            findAndScrolltoElement(class_name){
                let self = this
                if (document.getElementsByClassName(class_name).length == 0){
                    VueScrollTo.scrollTo('.timetable-bottom', 600, {
                        easing: 'ease-in-out',
                        offset: -120,
                        onDone: function(element) {
                          //self.findAndScrolltoElement(class_name)
                        },
                    });
                } else {
                    VueScrollTo.scrollTo('.'+ class_name, 600, {
                        easing: 'ease-in-out',
                        offset: -120,
                    });
                }
            },
            scroll_to_next_month(){
                this.findAndScrolltoElement('is_next_month')
            },
            scroll_to_next_week(){
                this.findAndScrolltoElement('is_next_week')
            },
            scroll_to_today(){
                 VueScrollTo.scrollTo('.is_today', 600, {
                    easing: 'ease-in-out',
                    offset: -120,
                });
            },
            isTabletOrMobileWidth(){
              return isTabletOrMobileWidth();
            },
            isDesktopWidth(){
              return isDesktopWidth();
            },
            onResize(){
                if (isDesktopWidth()){
                    // just show the first week worth of dates
                    if(this.days !== null && this.days.length > 7)
                      this.days = this.days.slice(0, 7);
                    this.next_link = this.first_next_link;
                    this.distance = -Infinity;
                } else {
                    this.distance = 400;
                }
            },
            getPrevWeek(){
                if (! this.loaded) return;

                this.loaded = false;
                if (isDesktopWidth()){
                  this.days = []
                }

                axios.get(this.prev_link)
                    .then(response => {

                        let thisDay = null;
                        let results = response.data.results;
                        this.processResults(results, thisDay);

                        let start_param = this.prev_link.substring(this.prev_link.indexOf("start=") + 6);
                        updateQueryStringParam('start', start_param);

                        this.next_link = response.data.next_link;
                        this.prev_link = response.data.prev_link;
                        this.loaded = true;
                    });
            },
            getNextWeek(){
                if (! this.loaded) return;

                this.loaded = false;
                if (isDesktopWidth()){
                  this.days = []
                }
                axios.get(this.next_link)
                    .then(response => {

                        let thisDay = null;
                        let results = response.data.results;
                        this.processResults(results, thisDay);

                        let start_param = this.next_link.substring(this.next_link.indexOf("start=") + 6);
                        updateQueryStringParam('start', start_param);

                        this.next_link = response.data.next_link;
                        this.prev_link = response.data.prev_link;
                        this.loaded = true;
                    });
            },
            processResults(results, thisDay){
                results.map(function(occurrence, index, arr){
                  let start_obj = moment(occurrence.tz_start)
                  let day_key = start_obj.format('DD-MM-YY');
                  let hour = Number(start_obj.format('HH'));

                  if (day_key !== this.last_day){

                      if (thisDay !== null){
                          this.days.push(thisDay)
                      }

                      let month = start_obj.format('MMMM');
                      let new_month = false;

                      if (this.last_month != null && month !== this.last_month){
                          new_month = true;
                      }

                      this.last_month = month;

                      thisDay = {
                          name: start_obj.format('dddd'),
                          id: day_key,
                          nice_date: start_obj.format('Do MMMM'),
                          new_month: new_month,
                          month: start_obj.format('MMMM'),
                          sessions: { 'morning': [], 'afternoon': [], 'evening': []}
                      }
                      this.last_day = day_key;
                  }

                  if (hour < MORNING_CUTOFF){
                      thisDay.sessions.morning.push(occurrence)
                  } else if (hour >= MORNING_CUTOFF && hour < AFTERNOON_CUTOFF){
                      thisDay.sessions.afternoon.push(occurrence)
                  } else {
                      thisDay.sessions.evening.push(occurrence);
                  }
                  //thisDay.sessions.push(occurrence)

                }, this);
                if (thisDay !== null)
                   this.days.push(thisDay)
            },
            retrieveSchedule($state) {
                if ($state){

                    if(isDesktopWidth()){

                        return;
                    }
                }
                axios.get(this.next_link)
                    .then(response => {

                        let thisDay = null;
                        if (this.days === null){
                          this.days = [];
                        }

                        this.prev_link = response.data.prev_link;
                        this.next_link = response.data.next_link;
                        if (this.first_next_link == null){
                            this.first_next_link = this.next_link;
                        }

                        let results = response.data.results;
                        this.processResults(results, thisDay);

                        this.loaded = true;
                        if (! this.initialLoaded) this.initialLoaded = true;

                        if ($state && $state.loaded){
                            if (results && results.length > 0){
                                $state.loaded();
                            } else {
                                $state.complete();
                            }

                        } else {
                            if(! isDesktopWidth()) {
                                this.distance = 400;
                            }
                        }
                        })
                    .catch(e => {
                        this.loaded = true;
                        this.errors.push(e)
                        if ($state && $state.loaded){
                            $state.complete();
                        }
                    })
            },

        }
    };
</script>

<style scoped>

</style>