

/**
 * Constants of states for media playback
 * @enum {string}
 */
const PLAYER_STATE = {
    IDLE: 'IDLE',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    PLAYING: 'PLAYING',
    PAUSED: 'PAUSED',
    STOPPED: 'STOPPED',
    ERROR: 'ERROR'
};

const PLAYER_ID = '5304F480';

/** @const {number} Time in milliseconds for minimal progress update */
const TIMER_STEP = 1000;

/** @const {number} Cast volume upon initial connection */
const DEFAULT_VOLUME = 0.5;

/** @const {number} Height, in pixels, of volume bar */
const FULL_VOLUME_HEIGHT = 100;

/**
 * Cast player object
 * Main variables:
 *  - PlayerHandler object for handling media playback
 *  - Cast player variables for controlling Cast mode media playback
 *  - Current media variables for transition between Cast and local modes
 * @struct @constructor
 */
export default class CastPlayer {

    constructor(localPlayer){

        /** @type {PLAYER_STATE} A state for media playback */
        this.playerState = PLAYER_STATE.IDLE;

        /* Cast player variables */
        /** @type {cast.framework.RemotePlayer} */
        this.remotePlayer = null;
        /** @type {cast.framework.RemotePlayerController} */
        this.remotePlayerController = null;

        /* Current media variables */
        /** @type {number} A number for current media index */
        this.currentMediaIndex = 0;
        /** @type {number} A number for current media time */
        this.currentMediaTime = 0;
        /** @type {number} A number for current media duration */
        this.currentMediaDuration = -1;
        /** @type {?number} A timer for tracking progress of media */
        this.timer = null;

        this.localPlayer = null;
        /** @type {function()} */
        this.incrementMediaTimeHandler = this.incrementMediaTime.bind(this);

        if (window['__onGCastApiAvailable']){
            if (window['goytvCastAvailable']){
                this.initializeCastPlayer(localPlayer)
            } else {
                let breakCounter = 0;
                let checkInterval = setInterval(() => {
                    if (breakCounter >= 10){
                        clearInterval(checkInterval);
                        return;
                    }
                    if (window['goytvCastAvailable']){
                        this.initializeCastPlayer(localPlayer);
                        clearInterval(checkInterval);
                        return
                    }
                    breakCounter++;
                }, 1000);
            }
        }


    }


    initializeCastPlayer(localPlayer) {
        this.localPlayer = localPlayer;
        var options = {};

        // Set the receiver application ID to your own (created in the
        // Google Cast Developer Console), or optionally
        // use the chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID
        options.receiverApplicationId = PLAYER_ID;

        // Auto join policy can be one of the following three:
        // ORIGIN_SCOPED - Auto connect from same appId and page origin
        // TAB_AND_ORIGIN_SCOPED - Auto connect from same appId, page origin, and tab
        // PAGE_SCOPED - No auto connect
        options.autoJoinPolicy = chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED;

        cast.framework.CastContext.getInstance().setOptions(options);

        this.localPlayer.castInitialized();

        this.remotePlayer = new cast.framework.RemotePlayer();

        this.remotePlayerController = new cast.framework.RemotePlayerController(this.remotePlayer);
        this.remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
            this.switchPlayer.bind(this)
        );

    }

    requestSession(){
        let currentSession = cast.framework.CastContext.getInstance().getCurrentSession();
        if (currentSession == null){
            cast.framework.CastContext.getInstance().addEventListener(
                cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
                this.sessionStateChanged.bind(this)
            )
            cast.framework.CastContext.getInstance().requestSession();
        } else {
            this.loadMedia(this.localPlayer.video_url, this.localPlayer.title,
                this.localPlayer.captions_url, this.localPlayer.header_url)
        }
    }

    endSession(){
        let currentSession = cast.framework.CastContext.getInstance().getCurrentSession();
        // End the session and pass 'true' to indicate
        // that receiver application should be stopped.
        currentSession.endSession(true);
    }

    sessionStateChanged(sessionState){

        if (sessionState.sessionState == 'SESSION_STARTING'){
            this.localPlayer.castLoading();
        } else if (sessionState.sessionState == 'SESSION_STARTED'){
            this.loadMedia(this.localPlayer.video_url, this.localPlayer.title,
                this.localPlayer.captions_url, this.localPlayer.header_url)
        } else if (sessionState.sessionState == 'SESSION_ENDED'){
            this.localPlayer.castEnded(this.remotePlayer.currentTime)
        }
    }

    loadMedia(media_url, title, captions, thumbnail){
        let castSession = cast.framework.CastContext.getInstance().getCurrentSession();
        var mediaInfo = new chrome.cast.media.MediaInfo(
            media_url, 'application/vnd.apple.mpegurl');

        mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
        mediaInfo.metadata.metadataType = chrome.cast.media.MetadataType.GENERIC;
        mediaInfo.metadata.title = title;
        mediaInfo.metadata.images = [
            {'url': thumbnail}];

        if (captions && captions.length > 0){
            let englishSubtitle = new chrome.cast.media.Track(1, // track ID
              chrome.cast.media.TrackType.TEXT);
            englishSubtitle.trackContentId = captions;
            englishSubtitle.trackContentType = 'text/vtt';
            englishSubtitle.subtype = chrome.cast.media.TextTrackType.CAPTIONS;
            englishSubtitle.name = 'English Subtitles';
            englishSubtitle.language = 'en-AU';
            englishSubtitle.customData = null;

            let textTrackStyle = new chrome.cast.media.TextTrackStyle();
            //textTrackStyle.foregroundColor = '#80FF0000';
            mediaInfo.textTrackStyle = textTrackStyle;
            mediaInfo.tracks = [englishSubtitle];

        }

        var request = new chrome.cast.media.LoadRequest(mediaInfo);
        if (this.localPlayer.isShowingCaptions){
            request.activeTrackIds = [1]
        }

        castSession.loadMedia(request).then(
            this.castloaded.bind(this),
            function (errorCode) {
                this.playerState = PLAYER_STATE.ERROR;
                console.log('Remote media load error: ' +
                    this.getErrorMessage(errorCode));
            }.bind(this));
    }

    castloaded(){
        let currentSession = cast.framework.CastContext.getInstance().getCurrentSession();

        this.localPlayer.castLoaded(currentSession.getCastDevice().friendlyName, this.remotePlayer.duration)
    }

    switchPlayer() {
        console.log('switch player');
        if (cast && cast.framework) {
            if (this.remotePlayer.isConnected) {
                this.setupRemotePlayer();
                this.castloaded()
                return;
            }
        }
    }

    play(){
        if (this.remotePlayer.isPaused) {
            this.remotePlayerController.playOrPause();
        }
    }

    pause(){
        if (! this.remotePlayer.isPaused) {
            this.remotePlayerController.playOrPause();
        }
    }

    mute() {
        if (!this.remotePlayer.isMuted) {
            this.remotePlayerController.muteOrUnmute();
        }
    }

    unMute(){
        if (this.remotePlayer.isMuted) {
            this.remotePlayerController.muteOrUnmute();
        }
    }

    showCaptions(){
        var tracksInfoRequest = new chrome.cast.media.EditTracksInfoRequest([1])
        cast.framework.CastContext.getInstance().b.getSessionObj().media[0].editTracksInfo(tracksInfoRequest, null, null)
    }

    hideCaptions(){
        var tracksInfoRequest = new chrome.cast.media.EditTracksInfoRequest([null])
        cast.framework.CastContext.getInstance().b.getSessionObj().media[0].editTracksInfo(tracksInfoRequest, null, null)
    }

    seekTo(time) {
        this.remotePlayer.currentTime = time;
        this.remotePlayerController.seek();
    }

    getCurrentMediaTime() {
        return this.remotePlayer.currentTime;
    }

    setupRemotePlayer() {
        console.log('setting up remote player')
        var castSession = cast.framework.CastContext.getInstance().getCurrentSession();

        // Add event listeners for player changes which may occur outside sender app
        this.remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
            function() {
                if (this.remotePlayer.isPaused) {
                    //this.playerHandler.pause();
                    this.localPlayer.onCastPaused();
                } else {
                    //this.playerHandler.play();
                    this.localPlayer.onCastPlaying();
                }
            }.bind(this)
        );

        this.remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
            function() {
                if (this.remotePlayer.isMuted) {
                    this.localPlayer.onCastMuted();
                } else {
                    this.localPlayer.onCastUnMuted();
                }
            }.bind(this)
        );

        this.remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
            function() {
                var newVolume = this.remotePlayer.volumeLevel * FULL_VOLUME_HEIGHT;
                var p = document.getElementById('audio_bg_level');
                p.style.height = newVolume + 'px';
                p.style.marginTop = -newVolume + 'px';
            }.bind(this)
        );

        // This object will implement PlayerHandler callbacks with
        // remotePlayerController, and makes necessary UI updates specific
        // to remote playback
        var playerTarget = {};

        playerTarget.play = function () {
            if (this.remotePlayer.isPaused) {
                this.remotePlayerController.playOrPause();
            }

            var vi = document.getElementById('video_image');
            vi.style.display = 'block';
            var localPlayer = document.getElementById('video_element');
            localPlayer.style.display = 'none';
        }.bind(this);

        playerTarget.pause = function () {
            if (!this.remotePlayer.isPaused) {
                this.remotePlayerController.playOrPause();
            }
        }.bind(this);

        playerTarget.stop = function () {
             this.remotePlayerController.stop();
        }.bind(this);




        playerTarget.getMediaDuration = function() {
            return this.remotePlayer.duration;
        }.bind(this);

        playerTarget.updateDisplayMessage = function () {
            document.getElementById('playerstate').style.display = 'block';
            document.getElementById('playerstatebg').style.display = 'block';
            document.getElementById('video_image_overlay').style.display = 'block';
            document.getElementById('playerstate').innerHTML =
                this.mediaContents[ this.currentMediaIndex]['title'] + ' ' +
                this.playerState + ' on ' + castSession.getCastDevice().friendlyName;
        }.bind(this);

        playerTarget.setVolume = function (volumeSliderPosition) {
            // Add resistance to avoid loud volume
            var currentVolume = this.remotePlayer.volumeLevel;
            var p = document.getElementById('audio_bg_level');
            if (volumeSliderPosition < FULL_VOLUME_HEIGHT) {
                var vScale =  this.currentVolume * FULL_VOLUME_HEIGHT;
                if (volumeSliderPosition > vScale) {
                    volumeSliderPosition = vScale + (pos - vScale) / 2;
                }
                p.style.height = volumeSliderPosition + 'px';
                p.style.marginTop = -volumeSliderPosition + 'px';
                currentVolume = volumeSliderPosition / FULL_VOLUME_HEIGHT;
            } else {
                currentVolume = 1;
            }
            this.remotePlayer.volumeLevel = currentVolume;
            this.remotePlayerController.setVolumeLevel();
        }.bind(this);


        playerTarget.isMuted = function() {
            return this.remotePlayer.isMuted;
        }.bind(this);


        // Setup remote player volume right on setup
        // The remote player may have had a volume set from previous playback
        if (this.remotePlayer.isMuted) {
            this.playerHandler.mute();
        }

    }

    startProgressTimer() {
        this.stopProgressTimer();

        // Start progress timer
        this.timer =
            setInterval(this.incrementMediaTimeHandler, TIMER_STEP);
    }

    /**
     * Stops the timer to increment the media progress bar
     */
    stopProgressTimer() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    /**
     * Helper function
     * Increment media current position by 1 second
     */
    incrementMediaTime() {
        // First sync with the current player's time
        this.currentMediaTime = this.playerHandler.getCurrentMediaTime();
        this.currentMediaDuration = this.playerHandler.getMediaDuration();

        if (this.playerState === PLAYER_STATE.PLAYING) {
            if (this.currentMediaTime < this.currentMediaDuration) {
                this.currentMediaTime += 1;
                this.updateProgressBarByTimer();
            } else {
                this.endPlayback();
            }
        }
    }

    getErrorMessage(error) {
        switch (error.code) {
            case chrome.cast.ErrorCode.API_NOT_INITIALIZED:
                return 'The API is not initialized.' +
                    (error.description ? ' :' + error.description : '');
            case chrome.cast.ErrorCode.CANCEL:
                return 'The operation was canceled' +
                    (error.description ? ' :' + error.description : '');
            case chrome.cast.ErrorCode.CHANNEL_ERROR:
                return 'A channel to the receiver is not available.' +
                    (error.description ? ' :' + error.description : '');
            case chrome.cast.ErrorCode.EXTENSION_MISSING:
                return 'The Cast extension is not available.' +
                    (error.description ? ' :' + error.description : '');
            case chrome.cast.ErrorCode.INVALID_PARAMETER:
                return 'The parameters to the operation were not valid.' +
                    (error.description ? ' :' + error.description : '');
            case chrome.cast.ErrorCode.RECEIVER_UNAVAILABLE:
                return 'No receiver was compatible with the session request.' +
                    (error.description ? ' :' + error.description : '');
            case chrome.cast.ErrorCode.SESSION_ERROR:
                return 'A session could not be created, or a session was invalid.' +
                    (error.description ? ' :' + error.description : '');
            case chrome.cast.ErrorCode.TIMEOUT:
                return 'The operation timed out.' +
                    (error.description ? ' :' + error.description : '');
        }
    }


}
