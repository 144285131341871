<template>
    <div class="footer-subscribe-container">
        <div v-if="loading">
            <div class="item-loading">
                <div class="loading-spinner dark"></div>
            </div>
        </div>
        <div v-else-if="subscribed" key="2" class="footer-subscribe-success">
            <div>
                <h2>Thank You</h2>
                <p>You'll hear from us soon!</p>
            </div>
        </div>
        <div v-else class="footer-subscribe-form">
              <input :class="{has_error: has_error, is_valid: is_valid, is_subscribed: subscribed }"
                     v-on:keyup="do_validate" type="email" name="email" v-model="email" :placeholder="placeholder"/>
            <button v-on:click="do_subscribe">Subscribe</button>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import { validEmail } from '../../utils'

    export default {
        name: "FooterSubscribe",

        data: function(){
            return {
              email: '',
              placeholder: 'Enter Your Email Address',
              loading: false,
              subscribed: false,
              is_valid: false,
              has_error: false,
              has_focus: false,
              error_message: 'Oops! Please enter a valid email address'
            }
        },
        methods: {
            hasError(){
              return this.has_focus &&
                  (this.email.trim().length == 0 || !validEmail(this.email.trim()) ) || this.has_error
            },
            isValid(){
              return (this.email.trim().length > 0 && validEmail(this.email.trim()) )
            },
            onFocus(){
              this.has_focus = true;
              this.has_error = false;
            },
            onBlur(){
              this.has_focus = false;
            },
            onInput(ev){
              this.email = ev.target.value;
            },
            do_validate(event){
              if (this.isValid()){
                this.is_valid = true;
                this.has_error = false;
                if (event.which === 13){
                  this.do_subscribe()
                }
              } else {
                this.is_valid = false;
                this.has_error = true;
              }
            },
            do_subscribe(){
                if (!this.isValid()) {
                  this.is_valid = false;
                  this.has_error = true;
                  return false;
                }
                this.loading = true
                this.submitSubscribeForm(this.email).then((response) => {
                    this.loading = false;
                    this.submitLabel = "Subscribe";
                    if (response == 'OK'){
                        this.subscribed = true;
                        /*
                        gtag('event', 'email_subscribe', {
                          'response': 'success'
                        });
                        fbq('trackCustom', 'EmailSubscribe', {'response': 'success'});*/
                    } else {
                        this.serverError = response;
                        /*
                        gtag('event', 'email_subscribe', {
                          'response': 'fail'
                        });
                        fbq('trackCustom', 'EmailSubscribe', {'response': 'failed'});*/
                    }
                });
            },
            submitSubscribeForm(email){
                const url = '/api/mailinglist/subscribe';
                return axios.post(url, {
                        email: email
                    }, {
                        xsrfCookieName: 'csrftoken',
                        xsrfHeaderName: 'X-CSRFToken'
                    }).then(response => response.data)
            }
        }
    }
</script>
<style scoped lang="scss">
.footer-subscribe-container{
  input, button {
    width: 100%;
  }
  input {
    margin-bottom: 16px;
  }

  input.has_error {
    border: 1px solid #f00668;
  }

  input.is_valid {
    border: 1px solid #15b249;
  }

  @media all and (min-width: 640px) {
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
    input {
      margin-top: 12px;
    }
  }

  @media all and (min-width: 1024px) {
    max-width: none;
    .footer-subscribe-form{
      display: flex;
      margin-top: 14px;
      input {
        margin: 0 -3px 0 0;
        flex-grow: 3;
      }
      button {
        flex-grow: 1;
        font-size: 16px;
        width: 200px
      }
    }
  }

  @media all and (min-width: 1280px) {
    .footer-subscribe-form {
      display: flex;
      margin-top: 28px;
      button{
        width: 280px;
      }
    }
  }
}
</style>