import {buttonActive, buttonLoading} from "./utils";

function doStripePayment(cardElement){
    let payment_intent_id = document.getElementById("id_payment_intent_secret").value;
    if (payment_intent_id && payment_intent_id.length > 0){
        window.goy_stripe.confirmCardPayment(payment_intent_id, {
            payment_method: {card: cardElement},
          }).then((result) => {
        if (result.error) {
            document.querySelector(".payment-errors").innerHTML = result.error.message;
            let paymentButton = document.querySelector('.btn-payment-form');
            buttonActive(paymentButton);
        } else {
          document.getElementById("id_stripe_token").value = result.paymentIntent.id;
          document.getElementById('payment_form').submit();
        }
    });
    }

}

function doCreatePaymentMethod(card){
    window.goy_stripe.createPaymentMethod({
        type: 'card',
        card: card,
    }).then((result) => {
        if (result.error) {
            document.querySelector(".payment-errors").innerHTML = result.error.message;
            let paymentButton = document.querySelector('.btn-payment-form');
            buttonActive(paymentButton);
        } else {
          document.getElementById("id_stripe_token").value = result.paymentMethod.id;
          document.getElementById('payment_form').submit();
        }
    });
}

export function initializeCreditCardForm(){
    let checkoutButton = document.querySelector('.btn-payment-form');

    var elements = window.goy_stripe.elements();
    var cardElement = elements.create('card',{
        hidePostalCode: true,
        style: {
          base: {
            color: "#4a4a4a",
            fontWeight: 500,
            fontFamily: "Muli, Arial",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            "::placeholder": {
              color: "#939393"
            }
          },
          invalid: {
            color: "#E25950"
          }
        }
    });
    cardElement.mount('#card-element');
    cardElement.on('change', (event) => {
        document.querySelector(".payment-errors").innerHTML = '';
        if (event.complete){
            checkoutButton.disabled =  false;
        }
    })

    checkoutButton.addEventListener('click', function(ev){
        ev.preventDefault();

        let subscription_type_id = document.getElementById('id_subscription_type_id').value;
        let card_update = document.getElementById('id_card_update').value;
        let paymentButton = document.querySelector('.btn-payment-form');
        buttonLoading(paymentButton, 'Processing')
        if (subscription_type_id.length > 0 || (card_update.length > 0 && card_update == 'True')) {
            doCreatePaymentMethod(cardElement);
        } else {
            doStripePayment(cardElement);
        }
    });
}
