<template>
    <div class="item-slider-item video-item" ref="item">
        <a :href="video.meta.html_url">
            <div class="item-slider-image-wrapper">
                <span class="video-coming-soon" v-if="video.coming_soon">Coming Soon!</span>

                <div class="video-item-play-icon" v-if="video.coming_soon == false"></div>
                <img v-if="video.coming_soon" src="/ui/client/img/thumbs/coming-soon-small.jpg" :alt="video.title" lazyload="on" srcset="/ui/client/img/thumbs/coming-soon-large.jpg" class="item-slider-image"/>
                <img v-else :src="video.thumbnail_image.url" :alt="video.title" lazyload="on" :srcset="video.thumbnail_image_2x.url" class="item-slider-image"/>
                <h3>{{ video.title }}</h3>
            </div>
        </a>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {getStyle} from "../../utils";

    export default {
        name: "VideoSliderItem",
        props: ['video'],


    };
</script>