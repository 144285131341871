<template>
    <div class="timetable-session" :class="{ 'timetable-bookings-closed': this.occurrence.bookings_closed || this.occurrence.cancelled }">
        <div class="timetable-session-info">
            <p class="session-time">
                {{ nice_start }} - {{ nice_end }}
            </p>
            <h3>{{ occurrence.event.title }}</h3>
            <p v-if="occurrence.event.subheading" class="session-subheading">{{ occurrence.event.subheading }}</p>
            <p v-if="occurrence.external_host" class="session-host">with {{ occurrence.external_host }}</p>
            <p v-else class="session-host">with {{ occurrence.session_host.first_name }} {{ occurrence.session_host.last_name }}</p>
            <p class="session-occupancy">{{ spaces_remaining }}</p>
        </div>
        <div class="timetable-session-button-container">
            <p v-if="this.occurrence.cancelled" class="bookings-closed">Class Not Running</p>
            <a v-else-if="this.in_waitlist" class="timetable-book-link" :href="this.waitlist_cancel_url" title="Remove me from the waitlist"><span>Leave </span>Waitlist</a>
            <div v-else-if="this.class_full">
                <p v-if="occurrence.waitlist_full" class="bookings-closed">Class Full</p>
                <a v-else class="timetable-book-link" :href="occurrence.waitlist_url" title="Add me to the waitlist"><span>Join </span>Waitlist</a>
            </div>
            <p v-else-if="this.occurrence.bookings_closed" class="bookings-closed">Bookings Closed</p>
            <a v-else-if="is_booked" class="timetable-book-link" :href="view_url" title="View Booking">View Booking</a>
            <a v-else class="timetable-book-link" :href="occurrence.booking_url" title="Book this session">Book<span> this Class</span></a>
        </div>
    </div>
</template>
<script>
    import moment from 'moment'
    export default {
        name: "TimetableSession",
        props: ['occurrence', 'index', "booked_ids", "waitlist_ids"],
        created(){
            this.is_booked = false;
            this.in_waitlist = false;
            this.class_full = this.occurrence.spots_remaining <= 0;

            for(let i=0; i< this.booked_ids.length; i++){
                let client_booking = this.booked_ids[i]
                for(let j=0; j<client_booking.booking.occurrences; j++){
                    if (client_booking.booking.occurrences[j] == this.occurrence.id){
                        this.view_url = '/view/' + client_booking.slug
                        this.is_booked = true;
                    }
                }
            }
            for(let i=0; i< this.waitlist_ids.length; i++){
                let waitlist_item = this.waitlist_ids[i]
                if (waitlist_item.occurrence.id == this.occurrence.id){
                    this.in_waitlist= true;
                    this.waitlist_cancel_url = '/remove-from-waitlist/'+ this.occurrence.id
                }
            }
        },
        computed: {
            nice_start: function(){
                return moment(this.occurrence.tz_start).format('h:mma')
            },
            nice_end: function(){
                return moment(this.occurrence.tz_end).format('h:mma')
            },
            spaces_remaining: function(){
                if (this.occurrence.spots_remaining > 0){
                    return this.occurrence.spots_remaining + ' spots left'
                } else {
                    return 'Class Full'
                }
            },
        }
    };
</script>